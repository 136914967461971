import { Middleware } from '@reduxjs/toolkit';

function getCookie(cname) {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export const disableAutoAddressSelection: Middleware = () => {
  return (next) => (action) => {
    if (action.type == 'checkout/setAddressList') {
      const locale = getCookie('pz-locale')?.split('-');

      if (locale.length) {
        return next({
          ...action,
          payload: action.payload.filter((address) => {
            return (
              address.country.code.toLowerCase() == locale[1].toLowerCase()
            );
          })
        });
      }
    }

    return next(action);
  };
};
